import { HOME_BODY_COLOR } from "styles/oneOffColor";

const styles = {
  chipsContainer: {
    pt: { xs: 2, md: 4 },
    pb: 2,
    px: { xs: 2, md: 0 },
    mb: { xs: 2, md: 0 },
    background: HOME_BODY_COLOR,
    display: "flex",
    overflowX: "auto",
    overflowY: "hidden",
    wrap: { xs: "none", md: "nowrap " },
  },
  selectedChip: {
    borderRadius: 2,
  },
};

export default styles;
