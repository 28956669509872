import { useMediaQuery, useTheme } from "@mui/material";
import { DiscoverViewContext } from "contexts/DiscoverView";
import {
  QuickSendDrawerContext,
  QuickSendTabView,
} from "contexts/QuickSendDrawer";
import { useContext } from "react";
import { isOnPage, routes } from "schemas/routes";

export const usePageSize = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isRecommendedPage = isOnPage(routes.recommendations);
  const isLargeRecommendedPage = isRecommendedPage && !isMobileScreen;
  const isOnboardingPage = isOnPage(routes.onboardingInfluencer);

  const isTwoColumnView =
    useMediaQuery("(max-width:800px)") && isLargeRecommendedPage;

  const { discoverTab, tabWidth } = useContext(DiscoverViewContext);
  const { brandView, setBrandView } = useContext(QuickSendDrawerContext);

  const hideBrandInfoViewDiscoverTab = () => {
    if (
      discoverTab &&
      isLargeRecommendedPage &&
      brandView !== QuickSendTabView.COLLAPSE
    ) {
      setBrandView(QuickSendTabView.COLLAPSE);
    } else if (!discoverTab && isLargeRecommendedPage) {
      setBrandView(QuickSendTabView.EXPAND);
    }
  };

  const hideBrandInfoTwoColumnView = () => {
    if (!isTwoColumnView) {
      return;
    }
  };

  const navigationWidth = isMobileScreen
    ? "100%"
    : discoverTab !== null
      ? `calc(100% - ${60 + tabWidth}px)`
      : "calc(100% - 60px)";

  return {
    isTwoColumnView,
    hideBrandInfoViewDiscoverTab,
    hideBrandInfoTwoColumnView,
    isLargeRecommendedPage,
    isOnboardingPage,
    isRecommendedPage,
    navigationWidth,
  };
};
