import Image from "@tiptap/extension-image";
import { NodeSelection } from "prosemirror-state";

interface ImageOptions {
  src?: string;
  alt?: string;
  title?: string;
  size?: "small" | "medium" | "large" | "full width" | "custom";
  width?: string;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    customImage: {
      setImage: (options: ImageOptions) => ReturnType;
    };
  }
}

const predefinedWidths: Record<string, string> = {
  small: "25px",
  medium: "120px",
  large: "250px",
  "full width": "max-content",
};

export default Image.extend({
  name: "custom-image",

  addAttributes() {
    return {
      // @ts-ignore
      ...Image.config.addAttributes(),
      width: {
        default: null,
      },
      style: {
        default: null,
      },
      size: {
        default: "custom",
        parseHTML: (element) => element.getAttribute("size-id"),
        renderHTML: (attributes) => {
          if (!attributes.size) {
            return {};
          }

          return {
            "size-id": attributes.size,
          };
        },
      },
    };
  },

  addCommands() {
    return {
      setImage:
        (options: ImageOptions) =>
        ({ tr, commands }) => {
          if (
            tr.selection instanceof NodeSelection &&
            tr.selection?.node?.type?.name === "custom-image"
          ) {
            return commands.updateAttributes("custom-image", options);
          }
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },

  addNodeView() {
    return ({ node, editor, getPos }) => {
      const {
        view,
        options: { editable },
      } = editor;
      const { width, textAlign, size, style } = node.attrs;
      const $wrapper = document.createElement("div");
      const $container = document.createElement("div");
      const $img = document.createElement("img");
      const iconStyle = "width: 24px; height: 24px; cursor: pointer;";

      const dispatchNodeView = (updatedWidth: string) => {
        if (typeof getPos === "function") {
          const newAttrs = {
            ...node.attrs,
            width: updatedWidth,
            size: "custom",
          };
          view.dispatch(view.state.tr.setNodeMarkup(getPos(), null, newAttrs));
        }
      };

      let containerWidth =
        size && size !== "custom"
          ? predefinedWidths[size]
          : width
            ? width
            : "inherit";

      if (!isNaN(containerWidth)) {
        containerWidth += "px";
      }

      $wrapper.setAttribute(
        "style",
        `display: inline-block; text-align: ${textAlign};`,
      );

      $wrapper.appendChild($container);
      if (style) {
        $container.setAttribute("style", style);
      }
      $container.setAttribute(
        "style",
        `${$container.style.cssText} width: ${containerWidth};`,
      );

      $container.appendChild($img);

      Object.entries(node.attrs).forEach(([key, value]) => {
        if (value === undefined || value === null) return;
        $img.setAttribute(key, value);
      });

      $img.setAttribute(
        "style",
        `${$img.style.cssText} display: block; width: 100%;`,
      );

      if (!editable) return { dom: $img };

      const dotsPosition = [
        "top: -4px; left: -4px; cursor: nwse-resize;",
        "top: -4px; right: -4px; cursor: nesw-resize;",
        "bottom: -4px; left: -4px; cursor: nesw-resize;",
        "bottom: -4px; right: -4px; cursor: nwse-resize;",
      ];

      $img.addEventListener("click", () => {
        //remove remaining dots and position controller
        if ($container.childElementCount > 2) {
          for (let i = 0; i < 4; i++) {
            $container.removeChild($container.lastChild as Node);
          }
        }

        $container.setAttribute(
          "style",
          `position: relative; outline: 1px dashed #6C6C6C; width: ${containerWidth}; cursor: pointer;`,
        );

        for (let index = 0; index < 4; index++) {
          const $dot = document.createElement("div");
          $dot.setAttribute(
            "style",
            `position: absolute; width: 9px; height: 9px; border: 1.5px solid #6C6C6C; border-radius: 50%; ${dotsPosition[index]}`,
          );

          $dot.addEventListener("mousedown", startResize);
          $dot.addEventListener("touchstart", startResize, {
            passive: true,
          });

          let isResizing = false;
          let startX: number, startWidth: number;

          function startResize(e: MouseEvent | TouchEvent) {
            e.preventDefault();
            isResizing = true;

            if (e instanceof MouseEvent) {
              startX = e.clientX;
            } else {
              startX = e.touches[0].clientX;
            }
            startWidth = $container.offsetWidth;

            document.addEventListener("mousemove", onMouseMove);
            document.addEventListener("mouseup", onMouseUp);
            document.addEventListener("touchmove", onMouseMove, {
              passive: false,
            });
            document.addEventListener("touchend", onMouseUp, { passive: true });
          }

          function onMouseMove(e: MouseEvent | TouchEvent) {
            if (!isResizing) return;

            let currentX: number;
            if (e instanceof MouseEvent) {
              currentX = e.clientX;
            } else {
              currentX = e.touches[0].clientX;
            }

            const deltaX =
              index % 2 === 0 ? -(currentX - startX) : currentX - startX;
            const newWidth = startWidth + deltaX;

            $container.style.width = newWidth + "px";
          }

          function onMouseUp() {
            if (isResizing) {
              isResizing = false;
            }
            dispatchNodeView($container.style.width);

            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", onMouseUp);
            document.removeEventListener("touchmove", onMouseMove);
            document.removeEventListener("touchend", onMouseUp);
          }

          $container.appendChild($dot);
        }
      });

      // remove the outline if clicked outside of image in the editor
      editor.view.dom.addEventListener("click", (e: MouseEvent) => {
        const $target = e.target as HTMLElement;
        const isClickInside =
          $container.contains($target) || $target.style.cssText === iconStyle;

        if (!isClickInside) {
          const containerStyle = $container.getAttribute("style");
          const newStyle = containerStyle?.replace(
            "outline: 1px dashed #6C6C6C;",
            "",
          );
          $container.setAttribute("style", newStyle as string);

          if ($container.childElementCount > 2) {
            for (let i = 0; i < 4; i++) {
              $container.removeChild($container.lastChild as Node);
            }
          }
        }
      });

      return {
        dom: $wrapper,
      };
    };
  },
});
